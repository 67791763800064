import styled from 'styled-components';
import mediaQueries from '../../../styles/mediaQueries';
import { colors } from '../../../styles/constants';
import { Centered } from '../../../components/Layout';
import Label from '../../../components/Label';
import Link from '../../../components/Link';
import Input from '../../../components/Input';
import Section from '../../../components/Section';
import { BetaHeading } from '../../../components/Typography';
import calculateRem from '../../../styles/calculateRem';
import Button from '../../../components/Button';

const cardStackBreakpoint = '720px';

export const CenteredWithGutters = styled(Centered)`
  max-width: 720px;
  margin: 16px auto;
  text-align: left;
`;

export const StyledLabel = styled(Label)`
  font-weight: 400;
  margin: 0;
`;

export const SubLabel = styled.p`
  line-height: 24px;
  margin-top: -6px;
  margin-bottom: 6px;
`;

export const BtnContainer = styled.div`
  margin: 0 auto;
`;

export const BottomLink = styled(Link)`
  color: ${colors.darkPurple};
  text-decoration: underline;
`;

export const HalfInput = styled(Input)`
  width: 50%;
  ${mediaQueries.mobile`
    width: 100%;
  `};
`;

export const CallLink = styled.a`
  text-decoration: none;
`;

export const Card = styled.div`
  color: ${colors.orange};
  background: ${colors.orange};
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.black};
  }
`;

export const InfoText = styled.span`
  line-height: 26px;
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 90%;
`;

export const CardInfoText = styled(BlackInfoText)`
  padding: 5px;
  white-space: nowrap;
  font-size: 18px;
`;

export const ModalSection = styled.div`
  font-size: 18px;
  color: darkPurple;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Anchor = styled.a`
  color: ${colors.white};
  padding: 0.75rem 0;
  margin: 0 0.75rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    filter: 'none'
  },
  content: {
    width: '30%',
    maxWidth: '700px',
    height: '360px',
    left: '67%',
    marginTop: '130px',
    backgroundColor: 'purple',
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
    fontSize: '48px'
  }
};

if (window.innerWidth < 821) {
  modalStyles.content.height = '440px';
  modalStyles.content.fontSize = '28px';
  modalStyles.content.left = '20%';
  modalStyles.content.width = '55%';
}

export const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-left: 24px;
  padding-right: 24px;
  margin-left: 150px;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    padding: 0 12px;
    margin-top: 24px;
  }
`;

export const FormSection = styled(Section)`
  background: ${colors.white};
  height: fit-content;
  text-align: left;
  padding: 4%;
  border-radius: 14px;
  margin-bottom: 24px;
  border: 2px solid ${colors.orange};

  @media (max-width: ${cardStackBreakpoint}) {
    margin-bottom: 4px;
    padding: 20px 10px;
  }
`;

export const HeaderTextPurple = styled.p`
  font-size: 24px;
  margin-bottom: 8px;
  color: ${colors.purple};
`;

export const LeftAlignedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  height: fit-content;
  padding: 2%;
  margin-left: 24px;
  margin-top: 42px;
  background: ${colors.white};
  border-radius: 10px;

  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const BetaHeaderHeading = styled(BetaHeading)`
  margin-bottom: 8px;
  ${mediaQueries.mobile`
    display: none;
  `};
`;

export const LeftAlignedHeader = styled.div`
  text-align: left;
  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const ContentSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${calculateRem(16)};
`;

export const ButtonWrapper = styled.div`
  padding-top: ${calculateRem(24)};
  display: flex;
  justify-content: space-between;
`;

export const WideLink = styled(Button)`
  width: 350px;
  ${mediaQueries.mobile`
    width: 100%;
    margin-right: 0;
    padding: 0;
  `};
`;

export const MessageSection = styled(Centered)`
  max-width: ${calculateRem(720)};
  margin: ${calculateRem(16)} auto;
  padding: 0 ${calculateRem(16)};
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 40px;

  ${mediaQueries.mobile`
    text-align: center;
  `};
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SucessHeaderTextPurple = styled.p`
  font-size: 32px;
  margin-bottom: 8px;
  color: ${colors.purple};
  display: flex;
  align-items: center;
`;

export const ListingHeaderHeading = styled(BetaHeading)`
  margin-bottom: 16px;
  text-align: center;
  color: ${colors.purple};

  ${mediaQueries.mobile`
    font-size: 28px;
  `};
`;

export const ListingHeaderSection = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -72px;

  ${mediaQueries.mobile`
    margin-left: -24px;
  `};
`;
